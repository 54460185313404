<template>
    <section id="auth" class="main style1 special">
        <div class="container">
			<div class="row gtr-uniform gtr-50 align-left">
                <div class="col-6 col-12-medium">
                    <div class="row gtr-uniform gtr-50">
                        <div class="col-12">
                            <div class="input-group">
                                <input
                                    v-model="state.form.body.name"
                                    placeholder="Name"
                                    type="text"
                                />
                                <div class="text-danger text-sm">{{ state.form.errors.name }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input
                                    v-model="state.form.body.email"
                                    placeholder="Email"
                                    type="text"
                                />

                                <div class="text-danger text-sm">{{ state.form.errors.email }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input
                                    v-model="state.form.body.password"
                                    placeholder="Password"
                                    type="password"
                                />

                                <div class="text-danger text-sm">{{ state.form.errors.password }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input
                                    v-model="state.form.body.tos_pp"
                                    type="checkbox"
                                />
                                <label for="tos_pp">Accept terms of service.</label>
                                <div class="text-danger text-sm">{{ state.form.errors.tos_pp }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <input
                                v-model="state.form.remember"
                                type="checkbox"
                            />
                            <label for="remember">Remember Me</label>
                        </div>
                        <div class="col-12">
                            <input
                                v-model="state.form.staySignedIn"
                                type="checkbox"
                            />
                            <label for="staySignedIn">Stay Signed In</label>
                        </div>
                        <div class="col-12">
                            <input
                                v-model="state.form.fetchUser"
                                type="checkbox"
                            />
                            <label for="fetchUser">Fetch User</label>
                        </div>
                        <div class="col-12">
                            <input
                                v-model="state.form.autoLogin"
                                type="checkbox"
                            />
                            <label for="autoLogin">Auto Login</label>
                        </div>
                        <div class="col-12">
                            <ul class="actions">
                                <li><input
                                        @click="registerDefault"
                                        type="submit"
                                        value="Register"
                                        class="button small ankyra"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div> <!-- class="row gtr-uniform gtr-50" -->
                </div>
			</div>
        </div>
	</section>

</template>

<script>
    import {reactive } from 'vue';
    //import {useStore } from 'vuex';
    import {useRouter} from 'vue-router';
    import {useAuth  } from '@websanova/vue-auth/src/v3.js';
    import useAuthComp from '../../../src/composables/useAuthComp.js';

    export default {
        setup() {
            const auth     = useAuth();
            //const store    = useStore();
            const router   = useRouter();
            const authComp = useAuthComp();

            const state = reactive({
                form: {
                    body: {
                        name: '',
                        email: '',
                        password: '',
                        tos_pp: false,
                    },

                    errors: {},
                    remember: true,
                    fetchUser: true,
                    autoLogin: true,
                    staySignedIn: false,
                }
            });

            function errors(res) {
                state.form.errors = Object.fromEntries(res.data.errors.map(item => [item.field, item.msg]));
            }

            function registerDefault() {
                auth
                .register({
                    data: state.form.body,
                    remember: state.form.remember ? '{"name": "Default"}' : null,
                    fetchUser: state.form.fetchUser,
                    autoLogin: state.form.autoLogin,
                    staySignedIn: state.form.staySignedIn,
                })
                .then(null, (res) => {
                    errors(res.response);
                });
            }

            function registerRedirect() {
                auth
                .register({
                    data: state.form.body,
                    redirect: {name: 'user-account'},
                    remember: state.form.remember ? '{"name": "Redirect"}' : null,
                    fetchUser: state.form.fetchUser,
                    autoLogin: state.form.autoLogin,
                    staySignedIn: state.form.staySignedIn,
                })
                .then(null, (res) => {
                    errors(res.response);
                });
            }

            function registerThen() {
                auth
                .register({
                    data: state.form.body,
                    fetchUser: state.form.fetchUser,
                    autoLogin: state.form.autoLogin,
                    staySignedIn: state.form.staySignedIn,
                })
                .then(() => {
                    if (state.form.remember) {
                        auth.remember(JSON.stringify({
                            name: auth.user().first_name
                        }));
                    }

                    router.push({name: 'user-account'});
                }, (res) => {
                    errors(res.response);
                });
            }

            function registerComp() {
                authComp
                .register({
                    body: state.form.body,
                    remember: state.form.remember,
                    fetchUser: state.form.fetchUser,
                    autoLogin: state.form.autoLogin,
                    staySignedIn: state.form.staySignedIn,
                })
                .then(null, (res) => {
                    errors(res.response);
                });
            }

            return {
                state,
                registerThen,
                registerComp,
                registerDefault,
                registerRedirect,
            };
        }
    }
</script>
